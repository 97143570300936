export default {
	name: 'MonthTitle',
	props: {
		value: {
			type: Date,
			required: true
		}
	},
	template: `
		<span>
			<span class="val">{{ year }}</span
			><span class="unit">年</span
			><span class="val">{{ month }}</span
			><span class="unit">月</span>
		</span>
	`,
	computed: {
		year () {
			return String(this.value.getFullYear())
		},
		month () {
			return String(this.value.getMonth() + 1)
		}
	}
}
