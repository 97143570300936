/**
 * カレンダーの凡例
 */
export default {
	name: 'Legend',
	props: {
		value: {
			type: Array,
			default () {
				return [
					{
						name: 'event',
						label: 'イベントがある日'
					}
				]
			}
		}
	},
	template: `
		<div class="event-calendar-legend-list">
			<div
				v-for="legend in value"
				:key="legend.name"
				class="event-calendar-legend-item"
			>
				<span
					class="event-calendar-legend-bar"
					:class="{ [legend.name]: true }"
				/>
				{{ legend.label }}
			</div>
		</div>
	`
}
