import axios from 'axios'

const doNothing = () => {}

export default class EventSource {
	constructor (baseUrl) {
		this.baseUrl = baseUrl
		const now = new Date()
		const epoch = new Date(now.getFullYear(), now.getMonth() - 2, 1)
		this.startMonth = epoch.getMonth()
		this.cache = {}
	}

	fetch (month, callback) {
		const tounen = month.getMonth() >= this.startMonth
		const startYear = month.getFullYear() + (tounen ? 0 : -1)
		this.fetchYearMonth(startYear, this.startMonth, callback)
	}

	preload (month) {
		const prevPeriodStart = new Date(month.getFullYear(), month.getMonth() - 2, 1)
		this.fetch(prevPeriodStart, doNothing)
		const nextPeriodStart = new Date(month.getFullYear(), month.getMonth() + 2, 1)
		this.fetch(nextPeriodStart, doNothing)
	}

	fetchYearMonth (year, month, callback) {
		const ym = this.formatYM(year, month)
		if (this.cache[ym] != null) {
			callback(this.cache[ym])
			return
		}
		this.cache[ym] = []

		const url = this.baseUrl + '/dates/' + ym + '.json'
		this.fetchJson(url, rawEvent => {
			const highlights = rawEvent.highlights.map(hl => {
				hl.dates = hl.dates.map(this.createDate)
				return hl
			})
			this.cache[ym] = {
				highlights,
				dateMax: new Date(rawEvent.dateMax),
				legends: rawEvent.legends || undefined
			}
			callback(this.cache[ym])
		})
	}

	formatYM (year, month) {
		return String(year) + '/' + String('0' + (month + 1)).slice(-2)
	}

	fetchJson (url, callback) {
		axios.get(url)
			.then(response => callback(response.data))
			.catch(err => console.warn(err))
	}

	createDate (dateStr) {
		return new Date(dateStr.replace(/-/g, '/'))
	}
}
