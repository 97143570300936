import { today, currentMonth } from './now'
import EventSource from './EventSource'
import Calendar from './Calendar'
import Legend from './Legend'

export default {
	name: 'EventCalendar',
	props: {
		sourceUrl: {
			type: String
		},
		month: {
			type: Date,
			default: currentMonth
		},
		today: {
			type: Date,
			default: today
		}
	},
	data () {
		const highlights = []
		return {
			min: currentMonth,
			max: currentMonth,
			eventSource: new EventSource(this.sourceUrl),
			aMonth: this.month,
			attrs: highlights,
			highlights,
			legends: undefined
		}
	},
	components: {
		Calendar,
		Legend
	},
	template: `
		<div class="event-calendar">
			<Calendar
				:month="month"
				:min="min"
				:max="max"
				:today="today"
				:attrs="attrs"
				@setMonth="setMonth"
				@click.native="handleClick"
			/>
			<Legend
				:value="legends"
			/>
		</div>
	`,
	mounted () {
		this.eventSource.fetch(this.month, event => {
			if (event.dateMax) {
				this.max = event.dateMax
			}
			this.setEventHighlights(event.highlights)
			this.legends = event.legends
		})
	},
	methods: {
		setMonth (month) {
			this.aMonth = month
			this.eventSource.preload(month)
			this.eventSource.fetch(month, event => {
				this.setEventHighlights(event.highlights)
			})
		},
		setEventHighlights (highlights) {
			const month = this.aMonth
			this.highlights.splice(0, this.highlights.length)
			highlights.forEach(item => {
				this.highlights.push({
					highlight: item.highlight,
					dates: item.dates.filter(date => date.getFullYear() === month.getFullYear() && date.getMonth() === month.getMonth())
				})
			})
		},
		handleClick (event) {
			if (!event.target || !event.target.classList) return
			const { target } = event
			const cl = target.classList
			const pcl = target.parentNode.classList
			if (cl.contains('bs-cal-title')) {
				this.navigateToMonthlyEventList(this.aMonth)
			} else if (cl.contains('bs-cal-num') && pcl.contains('bs-cal-col-hl-event') && !pcl.contains('bs-cal-col-hl-closed')) {
				const dateStr = target.getAttribute('data-date')
				if (!dateStr) return
				this.navigateToDailyEventList(dateStr)
			}
		},
		navigateToMonthlyEventList (month) {
			location.href = this.createURL('/' + month.getFullYear() + '/' + ('0' + String(month.getMonth() + 1)).slice(-2) + '/')
		},
		navigateToDailyEventList (dateStr) {
			location.href = this.createURL('/' + dateStr.replace(/-/g, '/') + '/')
		},
		createURL (url) {
			return this.sourceUrl + url
		}
	}
}
