/* global Vue */

/**
 * js/er/events.min.js
 *
 * イベントカレンダーの表示を行う。
 *
 * HTML の記述例:
 *
 * ```html
 * <div class="er-calendar"
 *     data-src-url="/event/"
 *     data-month="2021-05"
 * ></div>
 * ```
 *
 * このようにすると `/event/dates/2011/05.json` のような URL にカレンダーの情報を取得しにいく。
 */

import EventCalendar from './EventCalendar'
import { today, currentMonth } from './now'

function initAll () {
	[].slice.call(document.querySelectorAll('.er-calendar'))
		.filter(isVisible)
		.map(init)
}

function isVisible (el) {
	return el.clientWidth > 0 || el.clientHeight > 0
}

function init (container) {
	const sourceUrl = (container.getAttribute('data-src-url') || '').replace(/\/$/, '')
	const month = readMonth(container.getAttribute('data-month')) || currentMonth

	const app = new Vue({
		el: container,
		components: {
			EventCalendar
		},
		data () {
			return {
				props: {
					sourceUrl,
					month,
					today
				}
			}
		},
		template: '<EventCalendar v-bind="props" />'
	})
	return app
}

function readMonth (monthStr) {
	if (!monthStr) return null
	const match = monthStr.match(/^([0-9]{4})[/-]([0-9]{2})\b/)
	if (!match) return null
	return new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, 1)
}

initAll()
